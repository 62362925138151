import React from 'react'

const commingSoon = () => {
  return (
    <div className='flex items-center text-[#560216] font-black justify-center text-7xl h-screen'>
      Comming Soon!!!
    </div>
  )
}

export default commingSoon
